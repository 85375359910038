import React, {useState, useEffect, useMemo} from 'react'
import { useTranslation } from "react-i18next"
import { isNull } from '../../izUtils';

const TextInput = ({data, onChange, onBlur, refreshAfterChange, showErrors}) => {
    const { type, name, display, errors, title, disabled} = data
    const { t } = useTranslation();

    const [value, setvalue] = useState("")

    useEffect(() => {
        setvalue(data.value === null ? '' : data.value)
    }, [data.value])

    const handleChange = (e) => {
        setvalue(e.target.value)
        if (!isNull(refreshAfterChange) && refreshAfterChange) onChange(e.target.value, name);
    };
    
    let isError = useMemo(() => {
        return showErrors && !isNull(errors) && errors.length !== 0
    }, [errors, showErrors])

    if (display) {
        return (
            <div className="form-container">
                <label className="form-label" htmlFor={name}>{title}</label>
                <input className={"form-input" + (isError ? ' error' : '')}
                    type={type}
                    disabled={disabled}
                    id={name}
                    placeholder={t(`form.${data.name}`)}
                    value={value}
                    onChange={handleChange}
                    onBlur={() => isNull(onBlur) ? {} : onBlur(value, name)}
                />
            </div>
        )
    } else {
        return null
    }
}

export default TextInput