import React, { useMemo } from 'react'
import { displayDate/*, isNull*/ } from '../../izUtils'

const TextDisplay = ({data}) => {
    const {title, value, type} = data

    let displayValue = useMemo(() => {
        if (type === 'date') {
            return <div className="display-input">{displayDate(value)}</div>
        } else if (type === 'area') {
            return <div className="display-input" dangerouslySetInnerHTML={{__html: value.replace(/\n/g, "<br />")}} />
        } else if (type === 'multiSelect') {
            return value.map(val => {
                 return (
                    <div key={val.id} className="form-checkbox-group">
                        <input type="checkbox" className="form-checkbox" disabled={true} checked={true} />
                        <label className="checkmark"></label>
                        <label className="checkbox-label">
                            {val.title 
                                // + (!isNull(val.data) && (
                                //     (!isNull(val.data.manufacturer) ? ' - ' + val.data.manufacturer : '') +
                                //     (!isNull(val.data.system) ? ' - ' + val.data.system : '') +
                                //     (!isNull(val.data.central_station) ? ' - ' + val.data.central_station : '')
                                // ))
                            }
                        </label>
                    </div>
                 )
            })
        } else {
            return <div className="display-input">{value}</div>
        }

    }, [type, value])

    return (
        <div className="form-container display-container">
            <label className="form-label display-label">{title}</label>
            {displayValue}
        </div>
    )
}

export default TextDisplay