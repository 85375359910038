import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Swal from "sweetalert2";

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import { transformer } from '../../hoc/helpers/transformer'
import { linker } from '../../hoc/helpers/linker'
import Button from '../../hoc/Button/Button'
import Loader from '../../hoc/Loader/Loader'
import { useMainStore } from '../../store'
import { errorStatus } from '../../hoc/helpers/errorStatus'
import FileUpload from '../../hoc/FileUpload/FileUpload';

const TaskCreate = () => {
    const { t } = useTranslation()
    const Navigate = useNavigate()
    const { clientId, facilityId } = useParams()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false);

    const lsTaskFiles = 'AKODA-mobile.taskFiles';

    useEffect(() => {
        localStorage.removeItem(lsTaskFiles)

        let qrParams = null;
        if ((!isNull(clientId)) && (!isNull(facilityId))) qrParams = { clientId, facilityId };
        getData(false, qrParams);
    }, [headers]) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = (save, qrParams) => {
        let payload = { save };

        if (!isNull(inputs)) {
            // Get data from inputs
            const keys = Object.keys(inputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(inputs[keys[i]].value) && inputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = inputs[keys[i]].value;
                }
            }

            // Get data from localStorage
            let taskFiles = JSON.parse(localStorage.getItem(lsTaskFiles))
            if (!isNull(taskFiles)) {
                if (isNull(payload.data)) payload.data = {};
                payload.data.client_files = taskFiles.map(file => file.id);
            }
        } else if (!isNull(qrParams)) {
            payload.data = {
                client: qrParams.clientId,
                facility: qrParams.facilityId,
            };
        }

        axios.post(env.api + '/api/qr/task/create', payload, {headers}).then(response => {
            if (save) {
                if (response.data.state === 'success') {
                    Swal.fire({
                        title:  t('task.create.success.title'),
                        text:  response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--priamryColor)',
                        confirmButtonText: t('ok')
                    }).then(() => Navigate('/tasks'))
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                }
            } else {
                if (!isNull(response.data.error)) {
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data))
        }).catch(error => {
            axiosError(error);
        });
    }

    const handleChange = (value, name, type) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        setInputs(clonedInputs);
        getData(false, null);
    }

    const handleMulti = (value, id) => {
        let clonedInputs = {...inputs};
        if (clonedInputs[id].value.length === 0) {
            clonedInputs[id].value.push(value)
        } else {
            if (clonedInputs[id].value.indexOf(value) === -1) {
                clonedInputs[id].value.push(value);
            } else {
                const filteredValues = clonedInputs[id].value.filter(el => el !== value);
                clonedInputs[id].value = filteredValues;
            }
        }

        setInputs(clonedInputs);
        getData(false, null);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => handleChange(value, id),
            selectHandler: (value, id) => handleChange(value, id),
            multiHandler: (value, id) => handleMulti(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) {
        return <Loader />
    } else {
        return (
            <div className='container'>
                <div className='me-1 ms-1'>
                    <div className='mobile-title'>{t('task.create.header')}</div>
                    { ['client', 'facility', 'security_system', 'client_notes'].map(field => mapLinker(field)) }
                    <FileUpload data={inputs.client_files} saveToLS={lsTaskFiles} />
                    <div className='mt-5 mb-3'>
                        <Button title={t('task.create.btn')} onClick={() => getData(true, null)}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TaskCreate